import { createApp, reactive, ref, watchEffect } from 'vue'
import App from './App.vue';
import router from './router';
import { IonicVue } from '@ionic/vue';
import './firebaseConfig';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/icomoon.css';
/* Frappe ui */
import {
	setConfig,
	//frappeRequest,
	//pageMetaPlugin,
	//resourcesPlugin,
} from "frappe-ui"
import { session } from "@/data/session"
import { userResource } from "@/data/user"
import { customFrappeRequest } from '@/utils/customFrappeRequest'
import analyticsService from '@/data/analytics';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import * as Sentry from '@sentry/vue';
import { Capacitor } from '@capacitor/core';

declare var UXCam:any;

const app = createApp(App)
  .use(IonicVue, {mode: 'ios'})
  .use(router)
  .use(VueTelInput)

//app.use(resourcesPlugin)
//app.use(pageMetaPlugin)
setConfig("resourceFetcher", customFrappeRequest)

// Create a reactive searchTerm ref
const searchTerm = ref('')
const searchParameters = reactive({
    query_args: {
        field_filters: {},
        item_group: [],
        search: "",
        attribute_filters: {},
        start: null,
        from_filters: false,
        price_min: null,
        price_max: null,
        sort_by: null,
        brands: []
    },
    orFilters: [],
    limit: 24
});


// Provide the searchTerm to the application
app.provide('searchTerm', searchTerm)
app.provide('searchParameters', searchParameters)

// Fetch products based on searchTerm
watchEffect(() => {
  fetchProducts(searchParameters.query_args.item_group)
})

function fetchProducts(term) {
  // Fetch products based on the search term
  //console.log('Fetching products for:', term)
}

// setConfig('defaultListUrl','https://happygift-test.frappe.cloud/api/method/frappe.client.get_list')
// setConfig('defaultDocInsertUrl','https://happygift-test.frappe.cloud/api/method/frappe.client.insert')
// setConfig('defaultDocUpdateUrl','https://happygift-test.frappe.cloud/api/method/frappe.client.set_value')
// setConfig('defaultDocDeleteUrl','https://happygift-test.frappe.cloud/api/method/frappe.client.delete')
// setConfig('defaultDocGetUrl','https://happygift-test.frappe.cloud/api/method/frappe.client.get')
// setConfig('defaultRunDocMethodUrl','https://happygift-test.frappe.cloud/api/method/frappe.client.run_doc_method')

app.provide("$session", session)
app.provide("$user", userResource)

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN
    //integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    //tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    //replaysSessionSampleRate: 0.1,
    //replaysOnErrorSampleRate: 1.0,
});

router.isReady().then(() => {
    //montar app
    app.mount('#app');

    //configurar ux cam
    UXCam.optIntoSchematicRecordings() //To enable session video recording on iOS 
    const configuration = {
        userAppKey: import.meta.env.VITE_UXCAM_APP_KEY,
        enableAutomaticScreenNameTagging:false,
        enableImprovedScreenCapture:true,
    }

    UXCam.startWithConfiguration(configuration);
});

//donde pongo esto? es para web configuration
import { FacebookLogin } from '@capacitor-community/facebook-login';
//use hook after platform dom ready
async function initFacebookLogin() {
    await FacebookLogin.initialize({ appId: "159206672604701" });
}
initFacebookLogin();

function generateGuestId() {
    return 'guest-' + new Date().getTime() + '-' + Math.random().toString(36).substring(2, 10) +'@guest.com';
}

let isRedirecting = false;

router.beforeEach(async (to, from, next) => {

    const guestId = localStorage.getItem('guestId');
    let isLoggedIn = session.isLoggedIn;

    //HACE DOS VECES EL LLAMADO A USER RESOURCE. SERÁ POR EL RESET(). NO ES POR ESO
    if (!isRedirecting) {
        try {
            //console.log('llamando a user resource') //no entra aqui dos veces
            userResource.reload();
            await userResource.promise;
            //remover guest id?
            localStorage.removeItem('guestId');
        } catch (error) {
            //console.log('CATCH llamando a user resource')
            isLoggedIn = false;
            localStorage.removeItem('user_id');
            localStorage.removeItem('token');
            session.token = '';
            //userResource.reset();
            if (!guestId) localStorage.setItem('guestId', generateGuestId());
            //crear guest id si no existe
            //no hay que hacer un get del guestid?
        }
    }

    // Verificar si la ruta es `undefined` después del `try-catch`
    if (!to.name) {
        if (isLoggedIn) {
            //console.log('Ruta indefinida y usuario logueado. Redirigiendo al home.');
            next({ name: 'home' });
        } else {
            //console.log('Ruta indefinida y usuario no logueado. Redirigiendo al login.');
            next({ name: 'login' });
        }
        return; // Detener ejecución posterior para evitar llamadas innecesarias
    }

    if (to.meta.isPublic) {
        //console.log('Ruta pública');
        if (to.meta.isUnique) {
            //console.log(`Accediendo a una ruta pública única: ${to.name}`);
            next();
            return;
        }

        if (isLoggedIn) {
            const privateRouteMap = {
                homePublic: 'home',
                cataloguePublic: 'catalogue',
                itemPublic: 'item',
                cartPublic: 'cart',
                checkoutNotUser: 'checkout',
                wishListPublic: 'wishList'
            };
            const privateRoute = privateRouteMap[to.name] || 'home'; // Redirigir a HOME si no hay mapeo
            //console.log(`Está logueado. Redirigiendo de ${to.name} a ${privateRoute}.`);
            isRedirecting = true;
            next({ name: privateRoute, params: to.params, query: to.query });
            return;
        }
    } else {
        //console.log('Ruta privada');
        if (!isLoggedIn) {
            const publicRouteMap = {
                home: 'homePublic',
                catalogue: 'cataloguePublic',
                item: 'itemPublic',
                cart: 'cartPublic',
                checkout: 'checkoutNotUser',
                wishList: 'wishListPublic'
            };
            const publicRoute = publicRouteMap[to.name] || 'login'; // Redirigir a LOGIN si no hay mapeo
            if (!guestId) localStorage.setItem('guestId', generateGuestId()); //se ocupa al agregar el primer producto al carro de invitado
            //console.log(`No está logueado. Redirigiendo de ${to.name} a ${publicRoute}.`);
            isRedirecting = true;
            next({ name: publicRoute, params: to.params, query: to.query });
            return;
        }
    }

    //console.log('Navegación por defecto', to.name);
    isRedirecting = false;
    //solo mandar a analitycs las paginas finales, no antes de los redirect
    analyticsService.pageView(to.fullPath, to.name);
    analyticsService.setScreenName(to.fullPath);
    next(); // Permitir la navegación por defecto
});

//ROUTER HUSSAIN CON USER.RELOAD. vuelve a buscar a la bbdd si se esta o no logeado cada vez
// router.beforeEach(async (to, from, next) => {
// 	let isLoggedIn = session.isLoggedIn
// 	try {
// 		userResource.reload()
// 		await userResource.promise
// 	} catch (error) {
// 		isLoggedIn = false
// 	}

// 	if ((to.name === "login" || to.name === "signup") && isLoggedIn) {
// 		next({ name: "home" })
// 	} else if ((to.name !== "login" && to.name !== "signup") && !isLoggedIn) {
// 		next({ name: "login" })
// 	} else {
// 		next()
// 	}
// })
