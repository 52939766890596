import { createResource } from "frappe-ui"

/* export interface UserResource {
	data: undefined | { first_name: string; full_name: string, user_image: string }
	reload: () => void,
    reset: () => void
} */

export const userResource: any = createResource({
	url: "happygift.api.get_current_user_info",
	cache: "happygift:user",
	onError(error: any) {
		// console.log('Error getting user resource', error.exc_type)
		// if (error && error.exc_type === "AuthenticationError") {
		// 	// router.push({ name: "login" })
		// 	//console.log("Error in user resource", error);
		// }
	},
	onSuccess(data: any) {
		//console.log('User resource: ', data)
	}
})

export const socialRegistrationUser: any = createResource({
	url: 'happygift.api.social_registration',
	makeParams({ firstname, lastname, mobile, birth_date }) {
		return {
			firstname: firstname,
			lastname: lastname,
			mobile: mobile,
			birth_date: birth_date
		}
	},
	onSuccess(data){
		// console.log('Exito en el registro de usuario de social login', data)
	},
	onError(error){
		console.log('Error en registro de usuario de social login', error)
	}
})

export const deleteUser = createResource({
	url: "happygift.api.account_disabled",
	onSuccess(data) {
		//console.log('deleteUser:',data)
		localStorage.removeItem('user_id')
		localStorage.removeItem('token')
	},
	onError(error) {
		//console.log("error en deleteUser: ", error)
	}
})

export const addDevice = createResource({
	url:"happygift.happygift.doctype.user_device.user_device.add_user_device",
	onSuccess(data) {
		//console.log('Success addDevice', data)
	},
	onError(error) {
		//console.log("error en addDevice: ", error)
	}
})